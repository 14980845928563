<template>
  <base-section id="news">
    <!-- <base-section-heading title="Latest News">
      Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tenetur, nulla voluptas sed consequuntur ipsam temporibus
      impedit, laboriosam corrupti debitis.
    </base-section-heading> -->

    <v-container>
      <v-row>
        <v-col v-for="(news, i) in articles" :key="i" cols="12" md="4">
          <news-card
            :article="news"
            v-bind="news"
            :src="news.src"
            :html="null"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "SectionNews",

  components: {
    newsCard: () => import("@/components/news/Card.vue"),
  },

  data: () => ({
    articles: [
      {
        icon: "mdi-text",
        date: "Jan 12, 2020",
        category: "Design",
        comments: 5,
        slug: "streamlining-private-credit-deal-management-with-mavenx",
        title:
          "Streamlining Private Credit Deal Management - Unlocking Efficiency and Empowering Decision-Making with MavenX",
        text: "Private credit, also referred to as private debt, is a form of debt financing that involves the provision of funds by investment funds, rather than traditional lenders such as banks, bank-led syndicates, or public markets. This type of financing is commonly used in established markets like the US and Europe to fund buyouts, acquisitions, and expansion capital.[...]",

        html: `
          <p>first paragraph</p>

          <p>second paragraph</p>
        `,
        src: require("@/assets/article-1.png"),
        author: {
          name: "John Leider",
          blurb:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo, odio nostrum.",
          src: require("@/assets/author.png"),
        },
      },
      {
        icon: "mdi-text",
        date: "Oct 19, 2019",
        category: "Strategy",
        comments: 8,
        slug: "offshore-development-centers",
        title:
          "Offshore Development Centers - Benefits, Management, and Partner Selection",
        text: "The world is racing with modern technology and digital transformation. The demand for tech expertise has increased dramatically and shortage of valuable human resources in the USA, UK, Australia, and other countries has become a trouble for businesses. To be parallel in the race of these demands, Offshore Development Center (ODC)[...]",
        html: `
          <p>first paragraph article 2</p>

          <p>second paragraph article 2</p>
        `,
        src: require("@/assets/blog2.png"),
        author: {
          name: "John Leider",
          blurb:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo, odio nostrum.",
          src: require("@/assets/author.png"),
        },
      },
      {
        icon: "mdi-text",
        date: "Oct 19, 2019",
        category: "Strategy",
        comments: 8,
        slug: "how-spreadswift-can-help-financial-due-diligence-for-asset-managers",
        title:
          "How Spreadswift can help Financial Due Diligence for Asset Managers",
        text: "Financial statements play a crucial role in the business world, serving as essential articles that provide valuable insights into the financial health and performance of a company. These statements are not only crucial for tax calculation purposes, but they also hold immense significance for various institutions and stakeholders [...]",
        html: `
          <p>first paragraph article 2</p>

          <p>second paragraph article 2</p>
        `,
        src: require("@/assets/blog3_1.png"),
        author: {
          name: "John Leider",
          blurb:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo, odio nostrum.",
          src: require("@/assets/author.png"),
        },
      },
      {
        icon: "mdi-text",
        date: "Oct 19, 2019",
        category: "Strategy",
        comments: 8,
        slug: "understanding-digital-personal-data-protection-bill-2023",
        title: "Understanding Digital Personal Data Protection Bill 2023",
        text: "One of the most pivotal actions in the context of Digital India was carried out by the Indian Parliament when they recently ratified the Digital Personal Data Protection Bill of 2023. The enactment of the Digital Data Privacy Protection Bill constitutes  [...]",
        html: `
          <p>first paragraph article 2</p>

          <p>second paragraph article 2</p>
        `,
        src: require("@/assets/article-4.webp"),
        author: {
          name: "John Leider",
          blurb:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo, odio nostrum.",
          src: require("@/assets/author.png"),
        },
      },
      {
        icon: "mdi-text",
        date: "June 10, 2024",
        category: "Strategy",
        comments: 4,
        slug: "accelerating-financial-due-diligence-with-finscape",
        title: "Accelerating Financial Due Diligence with FinScape",
        text: "Financial analysis lies at the heart of strategic decision-making for asset managers. Enter FinScape, an advanced financial template creator designed specifically for asset management. Traditional financial analysis involves manually [...]",
        html: `
          <p>first paragraph article 2</p>

          <p>second paragraph article 2</p>
        `,
        src: require("@/assets/due-diligence.jpg"),
        author: {
          name: "John Leider",
          blurb:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo, odio nostrum.",
          src: require("@/assets/author.png"),
        },
      },
      {
        icon: "mdi-text",
        date: "June 11, 2024",
        category: "Strategy",
        comments: 4,
        slug: "data-warehousing-and-portfolio-accounting",
        title:
          "Data Warehousing in Partnership and Portfolio Accounting - Key Challenges, Solutions and Best Practices",
        text: "One of the ways that asset managers can manage and leverage their data more efficiently and effectively is by using a data warehouse. A data warehouse is a system that collects data from various operational databases and external sources, transforms it [...]",
        html: `
          <p>first paragraph article 2</p>

          <p>second paragraph article 2</p>
        `,
        src: require("@/assets/data-warehouse-pic.jpg"),
        author: {
          name: "John Leider",
          blurb:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo, odio nostrum.",
          src: require("@/assets/author.png"),
        },
      },
    ],
  }),
};
</script>
